import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc, runTransaction } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import {
  Dialog,
  DialogContent,
  IconButton,
  Box,
  CircularProgress,
  Alert,
  Snackbar
} from '@mui/material';
import {
  Close as CloseIcon,
  ZoomIn as ZoomInIcon
} from '@mui/icons-material';

const OrderStatus = {
  COMPLETED: "COMPLETED",
  PENDING: "PENDING",
  CANCELLED: "CANCELLED",
};

function OrderView() {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState(null);
  const uid = localStorage.getItem("uid");
  const { id: orderId } = useParams();
  const navigate = useNavigate();
  const currencySymbol = localStorage.getItem('currencySymbol');

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const db = getFirestore();
        const orderRef = doc(db, "all_orders", uid, "orders", orderId);
        const orderSnapshot = await getDoc(orderRef);
        if (orderSnapshot.exists()) {
          setOrder({ ...orderSnapshot.data(), id: orderSnapshot.id });
        } else {
          setError("No such order found!");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching order:", error);
        setError("Error loading order details");
        setLoading(false);
      }
    };

    fetchOrder();
  }, [orderId, uid]);

  const handleStatusUpdate = async () => {
    try {
      const updatedStatus =
        order.status === OrderStatus.PENDING
          ? OrderStatus.COMPLETED
          : OrderStatus.PENDING;

      const db = getFirestore();
      const orderRef = doc(db, "all_orders", uid, "orders", orderId);
      await runTransaction(db, async (transaction) => {
        const orderDoc = await transaction.get(orderRef);
        if (!orderDoc.exists()) {
          throw new Error("Order document does not exist!");
        }
        transaction.update(orderRef, { status: updatedStatus });
        setOrder({ ...order, status: updatedStatus });
      });
    } catch (error) {
      console.error("Error updating order status:", error);
      setError("Failed to update order status");
    }
  };

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    };
    return date.toLocaleString("en-US", options);
  };

  if (loading) {
    return (
      <>
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                <CircularProgress />
              </Box>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  if (!order) {
    return (
      <>
        <Header />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Alert severity="error">No order found</Alert>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {error && (
              <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={() => setError(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert onClose={() => setError(null)} severity="error">
                  {error}
                </Alert>
              </Snackbar>
            )}
            
            <div className="row">
              <div className="col-12 col-lg-10 col-xl-8 mx-auto">
                <div className="card shadow-sm">
                  <div className="card-body p-4">
                    {/* Header Section */}
                    <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
                      <div>
                        <h4 className="card-title mb-1">Order Details</h4>
                        <small className="text-muted">
                          {order.created_at ? formatDate(order.created_at.seconds) : "N/A"}
                        </small>
                      </div>
                      <div className="mt-2 mt-sm-0">
                        <button 
                          className={`btn btn-${order.status === OrderStatus.PENDING ? 'success' : 'warning'} btn-sm`}
                          onClick={handleStatusUpdate}
                        >
                          Set {order.status === OrderStatus.PENDING ? 'Complete' : 'Pending'}
                        </button>
                      </div>
                    </div>

                    {/* Order Info Grid */}
                    <div className="row g-3 mb-4">
                      <div className="col-12 col-md-6">
                        <div className="p-3 border rounded bg-light">
                          <h6 className="mb-3">Order Information</h6>
                          <div className="mb-2">
                            <label className="fw-bold d-block">Order ID:</label>
                            <span>{order.id}</span>
                          </div>
                          <div className="mb-2">
                            <label className="fw-bold d-block">Quantity:</label>
                            <span>{order.quantity || "N/A"}</span>
                          </div>
                          <div className="mb-2">
                            <label className="fw-bold d-block">Total Value:</label>
                            <span className="text-primary fs-5">
                              {order.total_cost ? `${currencySymbol}${order.total_cost}` : "N/A"}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="p-3 border rounded bg-light">
                          <h6 className="mb-3">Customer Information</h6>
                          <div className="mb-2">
                            <label className="fw-bold d-block">Name:</label>
                            <span>
                              {order.firstName ? order.firstName : "N/A"} {order.lastName ? order.lastName : ""}
                            </span>
                          </div>
                          <div className="mb-2">
                            <label className="fw-bold d-block">Phone:</label>
                            <span>{order?.phone || "N/A"}</span>
                          </div>
                          <div className="mb-2">
                            <label className="fw-bold d-block">Delivery Type:</label>
                            <span>{order.storePickup ? "Store Pickup" : "Delivery"}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Address Section */}
                    {!order.storePickup && (
                      <div className="mb-4">
                        <div className="p-3 border rounded">
                          <h6 className="mb-3">Delivery Address</h6>
                          <p className="mb-0">
                            {order.address ? 
                              `${order.address}, ${order.city}, ${order.zip}, ${order.state}, ${order.country}` : 
                              "No address provided"}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* Order Items Section */}
                    <div className="mb-4">
                      <h5 className="mb-3">Order Items</h5>
                      <div className="row g-3">
                        {order.nodes.map((node, index) => (
                          <div key={node.id} className="col-12 col-md-6 col-lg-4">
                            <div className="card h-100">
                              <div className="position-relative">
                                {node.previewImage ? (
                                  <>
                                <img 
                                  src={node.previewImage} 
                                  alt={`Item ${index + 1}`} 
                                  className="card-img-top"
                                  style={{ height: "200px", objectFit: "cover", cursor: "pointer", backgroundColor:'#D3D3D3' }}
                                  onClick={() => setSelectedImage(node.previewImage)}
                                />
                                <IconButton
                                  className="position-absolute top-0 end-0 m-2"
                                  sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                                  onClick={() => setSelectedImage(node.previewImage)}
                                >
                                  <ZoomInIcon />
                                </IconButton>
                                </>
                                ): (
                                  <Box style={{backgroundColor: 'grey',height:'200px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                                    <p style={{color:'#D3D3D3'}}>No Image Found</p>
                                  </Box>
                                )}
                              </div>
                              <div className="card-body">
                                <h6 className="card-title">Item {index + 1}</h6>
                                <div className="small text-muted">
                                  <p className="mb-1">Quantity: {node.quantity}</p>
                                  <p className="mb-1">Category: {node.size?.category?.label || 'N/A'}</p>
                                  <p className="mb-0">Price: ${node.size.price}</p>
                                  <p className="mb-0">Design Warning: {node?.designWarning || 'N/A'}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Status and Actions */}
                    <div className="border-top pt-4">
                      <div className="row align-items-center">
                        <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <label className="fw-bold me-2">Status:</label>
                            <span className={`badge bg-${order.status === OrderStatus.PENDING ? 'warning' : 'success'}`}>
                              {order.status}
                            </span>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="d-flex flex-wrap gap-2 justify-content-sm-end">
                            <a href={order.link} className="btn btn-primary">
                              <i className="fa fa-download me-2"></i>Download
                            </a>
                            <button className="btn btn-outline-secondary" onClick={() => navigate(-1)}>
                              Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Image Preview Modal */}
      <Dialog
        open={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent sx={{ p: 1 }}>
          <IconButton
            onClick={() => setSelectedImage(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Preview"
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '80vh',
                objectFit: 'contain',
                backgroundColor:'#D3D3D3'
              }}
            />
          )}
        </DialogContent>
      </Dialog>
      <Footer />
    </>
  );
}

export default OrderView;